<template>
  <div class="allsymbol">
    <van-sticky :z-index="9999">
      <div class="allsymbol-head">
        <NavBar>
          <van-icon
            slot="left"
            color="#fff"
            name="arrow-left"
            @click="$router.push('/quotes')"
          />
          <div class="allsymbol-title" slot="title" style="color: #fff">
            {{ $t("Mt4ApiTrade.addSymbol") }}
          </div>
        </NavBar>
      </div>
    </van-sticky>
    <div class="allsymbol-content">
      <van-tabs
        @click="onClick"
        @change="onChange"
        animated
        color="#13b5b1"
        title-active-color="#13b5b1"
        swipeable
        sticky
      >
        <van-tab v-for="(item, index) in classer" :title="item" :key="index">
          <van-list
            v-if="!records.length"
            v-model="loading"
            :finished="finished"
            @load="onLoad"
          >
            <van-cell
              v-for="(item, index) in list"
              :key="index"
              :title="item.symbol"
              style="font-weight: 750; color: #333"
            >
              <template #right-icon>
                <van-image
                  width="100"
                  height="100"
                  :src="addImg"
                  @click="addStock(item, index)"
                />
              </template>
            </van-cell>
            <template #finished v-if="list.length">
              <div>{{$t('app.noMoreData')}}</div>
            </template>
            <template #default v-if="!list.length">
              <div style="text-align: center;margin-top: .5rem;">{{$t('app.noMoreData')}}</div>
            </template>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { GetSymbols } from "@/api/mt4Api";
    import NavBar from "@/components/mt4/NavBar";
    import { Notify, Dialog } from 'vant';
export default {
  components: { NavBar },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      addImg: require("@/assets/image/mt4tabbar/add.png"),
      value: "",
      list: [],
      iconShow: true,
      flag: false,
      currect: 0,
      existSymbol: [],
      addArr: [],
      records: [],
      classer: [],
      mold: "FOREX",
      active: "",
    };
  },
  computed: {},
  filters: {},
  created() {
    GetSymbols().then((res) => this.classer.push(...res.msg.Groups));
    // this.tabChange();
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    const exist = localStorage.getItem("exits");
    this.addArr.push(...JSON.parse(exist));
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.tabChange();
      this.loading = false;
      if (this.list.length) {
        this.finished = true;
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onChange(name, title) {
      this.mold = title;
      this.onLoad();
    },
    onClick(name, title) {
      this.mold = title;
      this.onLoad();
    },
    abolish() {
      this.searchShow = false;
    },
    search() {
      this.searchShow = true;
    },
    onInput(val) {
      this.records = [];
      this.list.forEach((element) => {
        if (element.symbol.indexOf(val.toUpperCase()) != -1) {
          //console.log(element.ticket);
          this.records.push(element);
        }
      });
    },
    onSearch(val) {
      this.records = [];
      this.list.forEach((element) => {
        if (element.symbol.indexOf(val.toUpperCase()) != -1) {
          //console.log(element.ticket);
          this.records.push(element);
        } else {
            Notify({ type: 'danger', message: this.$t("nomore") });
        }
      });
    },
    back() {
      this.$router.replace("/quotes/addstock");
    },
    addStock(data, index) {
      this.addArr.push(data);
      let odd = JSON.parse(localStorage.getItem("odd"));
      odd.splice(
        odd.findIndex((item) => item.symbol == data.symbol),
        1
      );
      localStorage.setItem("odd", JSON.stringify(odd));
      localStorage.setItem("addArr", JSON.stringify(this.addArr));
      this.list.splice(index, 1);
        Notify({ type: 'success', message: this.$t("success") });
    },
    // tab切换
    tabChange() {
      this.list = JSON.parse(localStorage.getItem("odd")).filter(
        (item) => item.group_name == this.mold
      );
    },
  },
};
</script>

<style>
.allsymbol {
  margin-bottom: 1rem;
}
.allsymbol .van-cell .van-icon-plus {
  color: #13b5b1;
  margin-right: 0.2rem;
}

.van-cell .van-icon-fire {
  color: red;
}

.isShow {
  display: none;
}

.allsymbol-head .van-nav-bar {
  background-color: #13b5b1;
}

.allsymbol .van-image {
  width: 40px !important;
  height: 40px !important;
}

.allsymbol .van-card {
  margin-top: 0;
  padding: 1px;
}

.allsymbol .van-card__thumb {
  width: 1px;
  height: 1px;
  margin-right: 0;
}

.allsymbol .van-card__content {
  min-height: 0;
  height: 0;
}

.allsymbol .van-cell__title {
  display: flex;
  align-items: center;
}

.allsymbol .van-cell {
  padding: 6px 16px;
}
.allsymbol .van-tab__text {
  font-weight: 700;
}
.allsymbol-content .van-sticky--fixed {
  top: 46px;
}
</style>